import React from 'react';

function SolutionsSection() {
  return (
    <section className="solutions-section">
      <h1 className="section-heading mb-5">Solutions</h1>
      <div className="solutions-card-container">
        <div className="solutions-card">
          <h2 className="solutions-card-title">Go-to-Market MVP</h2>
          <p className="solutions-card-description">
            4 to 8 sprints (8 - 16 weeks) to build a Go-To-Market MVP to test with early adopters
            and get a sense for the product market fit. Depends on how lean we can make the scope.
          </p>
          <p className="solutions-card-description latoBold">USD 100 to 200K</p>
        </div>
        <div className="solutions-card">
          <h2 className="solutions-card-title">Towards Product-Market Fit</h2>
          <p className="solutions-card-description">
            8 to 18 sprints (18 - 36 weeks) to run the Superhuman product market fit framework and
            improve product-market fit. Depends on how lean we can make the scope.
          </p>
          <p className="solutions-card-description latoBold">USD 200 to 450K</p>
        </div>
      </div>
    </section>
  );
}

export default SolutionsSection;
