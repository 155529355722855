import React from 'react';

const CLIENTS_LOGO_DATA = [
  {
    src: require('../../../assets/images/connect/live-ops/bby-logo.svg').default,
    alt: 'BBBY',
  },
  {
    src: require('../../../assets/images/connect/live-ops/draft-kings-logo.svg').default,
    alt: 'Draft Kings',
  },
  {
    src: require('../../../assets/images/connect/live-ops/swvl-logo.svg').default,
    alt: 'swvl',
  },
  {
    src: require('../../../assets/images/connect/live-ops/lifeprint.svg').default,
    alt: 'lifeprint',
  },
];

function ClientsLogoSection() {
  return (
    <section className="live-ops__main">
      <h1 className="section-heading-small text-center mb-5">
        Trusted by 150 Startups, 9 Unicorns, and 3 Fortune 500s
      </h1>
      <div className="clients-logo-container">
        {CLIENTS_LOGO_DATA.map(({src, alt}) => (
          <img key={src} className="clients-logo-img" src={src} alt={alt} />
        ))}
      </div>
    </section>
  );
}

export default ClientsLogoSection;
