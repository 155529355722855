import React from 'react';
import {Link} from 'gatsby';
import {Event} from '../GAClickEvent';

// selected Case Studies
const selectedCaseStudies = [
  {
    id: 'decorist',
    featuredImageSrc: require('../../assets/images/verticals/ar-vr/decorist-featured.webp').default,
    logoSrc: require('../../assets/images/portfolio/projects/logos/decorist-3.webp').default,
    title: 'Interior design app for photorealistic rendering',
    caseStudyDescription: '',
    caseStudyContribution:
      'We decreased the time for designing a room and delivering a high-quality render from 2 days to 2 hours.',
    furtherdetails: [],
    caseStudyUrl: '/portfolio/decorist-room-builder',
    projectName: 'Decorist',
  },
  {
    id: 'Bed Bath and Beyond',
    featuredImageSrc:
      require('../../assets/images/services/web-development/bed-bath-beyond-feature-img.webp')
        .default,
    // logoSrc: require('../../assets/images/portfolio/projects/logos/lifeprint-logo.webp').default,
    title: 'Augmented Reality Application in E-commerce',
    caseStudyDescription: '',
    caseStudyContribution:
      'We developed product visualisations of bedding, kitchen appliances, furniture and curtains in an augmented reality environment.',
    furtherdetails: [
      {
        id: '1',
        title: '300+',
        content: 'AR-enabled products sold in the first week of launch',
      },
      {
        id: '2',
        title: '30%',
        content: 'Increase in sales from 2018 to 2019 for AR-enabled products',
      },
    ],

    caseStudyUrl: '/portfolio/fortune500-AR-transformation',
    projectName: 'Fortune 500 AR Transformation',
  },

  {
    id: 'Coinbase',
    featuredImageSrc: require('../../assets/images/coinbase/coinbase-featured.webp').default,
    // logoSrc: require('../../assets/images/portfolio/projects/logos/lifeprint-logo.webp').default,
    title: 'A leading cryptocurrency exchange platform',
    caseStudyDescription: '',
    caseStudyContribution:
      'We delivered Rosetta implementations for assets, Rosetta validations, and asset integrations to speed up the client’s asset integration efforts.',
    furtherdetails: [],

    caseStudyUrl: '/portfolio/cryptocurrency-exchange-platform',
    projectName: 'Cryptocurrency Exchange Platform',
  },

  {
    id: 'lifeprint',
    featuredImageSrc:
      require('../../assets/images/services/mobile-development/lifeprint-featured-2.webp').default,
    logoSrc: require('../../assets/images/portfolio/projects/logos/lifeprint-logo-2.webp').default,
    title: 'Portable Photo Printer with AR & Social Networking',
    caseStudyDescription: '',
    caseStudyContribution:
      'We helped Lifeprint convert their idea into a working POC, raise multiple rounds of funding, take the product global, and become an Apple retail partner.',

    furtherdetails: [
      {
        id: '1',
        title: '$300k',
        content: 'Raised over multiple funding rounds',
      },
      {
        id: '1',
        title: 'Apple',
        content: 'Global Retail Partner',
      },
      {
        id: '1',
        title: 'Warner Bros',
        content: 'Partnership for Harry Potter IP',
      },
    ],

    caseStudyUrl: '/portfolio/lifeprint',
    projectName: 'lifeprint',
  },
];

const SelectedCaseStudies = () => {
  const caseStudiesUI = selectedCaseStudies.map((caseStudy, index) => (
    <div key={caseStudy.id}>
      <>
        <div
          className="case-study-card case-study-odd"
          style={{
            backgroundImage: `linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0.59) 0%,
              rgba(0, 0, 0, 0.59) 100%
            ), url(${caseStudy.featuredImageSrc})`,
          }}
        >
          <div className="case-study-content col-lg-6 col-md-8 col-xs-12">
            {caseStudy.logoSrc && (
              <img src={caseStudy.logoSrc} className="case-study-logo" alt={caseStudy.id} />
            )}
            <div className="case-study-title mb-3 p-0"> {caseStudy.title} </div>
            <div className="case-study-description mb-3 "> {caseStudy.caseStudyDescription} </div>
            <div className="case-study-Contribution mb-3"> {caseStudy.caseStudyContribution} </div>
            {caseStudy.id !== 'lifeprint' ? (
              <div className="case-study-further-container-2 col-12 d-flex align-items-center  m-0 flex-row p-0">
                {caseStudy.furtherdetails.map((card, i) => (
                  <>
                    <div key={card.id} className=" col-lg-6 col-md-6 col-xs-12 pl-0 mb-4 pr-5">
                      <div className=" case-study-details-title p-0">{card.title}</div>

                      <div className="case-study-details-body p-0">{card.content}</div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <></>
            )}
            {caseStudy.id === 'lifeprint' ? (
              <div className="case-study-further-container-2 col-12 d-flex align-items-center  m-0 flex-row p-0">
                {caseStudy.furtherdetails.map((card, i) => (
                  <>
                    <div key={card.id} className=" col-lg-6 col-md-6 col-xs-12 pl-0 mb-4 pr-2">
                      <div className=" case-study-details-title p-0">{card.title}</div>

                      <div className="case-study-details-body p-0 pr-4">{card.content}</div>
                    </div>
                  </>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
          <Link
            onClick={() =>
              Event(
                `${caseStudy.projectName} Case Study`,
                `${caseStudy.projectName} Case Study Button is clicked`,
                'Homepage'
              )
            }
            to={caseStudy.caseStudyUrl}
          >
            <span className="case-study-link mr-2">View case study</span>
            <svg
              width="15"
              height="15"
              viewBox="0 0 21 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="inherit"
            >
              <path
                d="M1.11358 5.34386L18.332 5.30243L14.6914 2.04963C14.446 1.82173 14.4256 1.4488 14.6505 1.20017C14.8755 0.951553 15.2437 0.930835 15.4891 1.15874L20.2955 5.50961C20.4182 5.6132 20.5 5.77895 20.5 5.9447C20.5 6.11045 20.4386 6.27619 20.3159 6.37979L15.5096 10.8343C15.3868 10.9378 15.2437 11 15.1005 11C14.9573 11 14.7937 10.9171 14.671 10.7928C14.446 10.5442 14.4665 10.1713 14.7119 9.94336L18.4343 6.5041L1.09313 6.54553C0.765885 6.54553 0.5 6.27619 0.5 5.9447C0.5 5.6132 0.765885 5.34386 1.11358 5.34386Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="0.3"
              />
            </svg>
          </Link>
        </div>
      </>
    </div>
  ));

  return (
    <section className="container-fluid p-0">
      <div className="case-studies-container">{caseStudiesUI}</div>
    </section>
  );
};

export default SelectedCaseStudies;
