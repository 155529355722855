import React from 'react';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../Shared/Link/Link';
import tintashLogoSrc from '../../../assets/images/connect/live-ops/tintash-logo.png';
import headingImage from '../../../assets/images/connect/live-ops/heading-image.svg';

function HeadingSection() {
  return (
    <section className="live-ops__main heading-section">
      <a href="https://tintash.com/" className="heading-link" target="_blank" rel="noreferrer">
        <img src={tintashLogoSrc} alt="Tintash Logo" />
      </a>
      <div className="heading-container">
        <div className="heading-subsection">
          <div className="heading-title">
            We work with <span className="heading-title__blue">Frac CTOs</span> to deliver on their
            vision.
          </div>
          <p className="heading-description mb-lg-5">
            We are an emerging-tech consulting firm led by Stanford alumni. Since 2008, we’ve
            designed and developed custom software solutions for complex technology challenges.
          </p>
          <Link className="heading-btn" to="/contact">
            Get In Touch
          </Link>
        </div>
        <div className="heading-image-container">
          <img className="heading-image" src={headingImage} alt="heading" />
        </div>
      </div>
    </section>
  );
}
export default HeadingSection;
