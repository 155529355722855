import React from 'react';
import SelectedCaseStudies from '../../Home/SelectedCaseStudies';

function CaseStudySection() {
  return (
    <section className="case-study-section">
      <h1 className="section-heading mb-5">Case Studies</h1>
      <SelectedCaseStudies />
    </section>
  );
}

export default CaseStudySection;
