import React from 'react';
import {Helmet} from 'react-helmet';
import '../../assets/css/connect.css';
import '../../assets/css/live-ops.css';
import {Link} from 'gatsby';
import ClientTestimonialCard from '../About/ClientTestimonial/ClientTestimonialCard';

import Footer from '../Shared/Footer';
import HeadingSection from './Section/HeadingSection';
import ClientsLogoSection from './Section/ClientLogoSection';
import SolutionsSection from './Section/SolutionsSection';
import CaseStudySection from './Section/CaseStudySection';
import OurStoryImage from '../../assets/images/about/ourstory-feature-img.webp';

function CPTOTemplate() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>CPTO | Tintash</title>
      </Helmet>
      <HeadingSection />
      <ClientsLogoSection />
      <section className="company-page-story">
        <div className="container p-0 m-0">
          <div className="row justify-content-center align-items-center p-0 m-0">
            <div className="col-lg-6 col-md-12 our-story-text">
              <h1 className="latoBlack text-left heading">Our story</h1>
              <p className="latoRegular text-left description mt-3">
                It all started in 2007 with a breakfast at Hobee’s Palo Alto. Trained at Stanford
                d.school, both cofounders believed in prototyping solutions. And thus over the
                famous coffee cake, they decided to prototype their working relationship. It worked
                and became the foundation of Tintash's approach to every new client relationship.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 p-0">
              <img className="our-story-image " src={OurStoryImage} alt="Our Story " />
            </div>
          </div>
        </div>
      </section>
      <section className="company-client-section">
        <div className="container p-0">
          <div className="row justify-content-center align-items-center m-0">
            <div className="col-12 p-0">
              <h1 className="latoBlack text-center main-heading mb-5">
                What clients love about us
              </h1>
              <div className="row pt-3 m-0">
                <ClientTestimonialCard
                  title={<>Proactive communication</>}
                  description={
                    <>
                      Tintash understood our <br /> needs, and they were easy <br /> to communicate
                      with.
                    </>
                  }
                  name="Lionel Koh"
                  position="Director, Askvisor"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/stellar-communication.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Transparent processes</>}
                  description={
                    <>
                      They excel at best practices. We went in not knowing too much about iOS
                      development, so we trusted them to use the best practices to optimize the app.
                    </>
                  }
                  name="Niall Mcdermott"
                  position="Co-founder, Forfit"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/visibility-and-transparency.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Focus on delivery</>}
                  description={
                    <>
                      They were flexible and adaptable to the problem and issues encountered, and
                      very resourceful when dealing with issues; Extremely committed to delivering a
                      solid product.
                    </>
                  }
                  name="Patrick Cosgrove"
                  position="CTO, Lifeprint"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/delivery-process.svg').default
                  }
                />

                <ClientTestimonialCard
                  title={<>Rapid iterations towards excellence</>}
                  description={
                    <>
                      I think the most impressive thing is the ratio of professionalism to cost is
                      very high.
                    </>
                  }
                  name="David Cohen-Tanugi"
                  position="Co-Founder, Embr Labs"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/iterate-towards-excellence.svg')
                      .default
                  }
                />

                <ClientTestimonialCard
                  title={<>Complete project ownership</>}
                  description={
                    <>
                      Tintash Apps reliable workflow, helpful feedback, and long-term focus
                      contributed to a rewarding partnership.
                    </>
                  }
                  name="Basar Simitci"
                  position="CTO, Joyful Works"
                  imgSrc={
                    require('../../assets/images/company/icons/whyus/ownership-and-individual-responsibility.svg')
                      .default
                  }
                />

                <div className="col-lg-4 col-sm-12 col-md-6 mb-4 pb-1 d-flex ">
                  <div
                    className="card about-us get-started border-0 p-4 justify-content-center align-items-center w-100"
                    onClick={() => navigate('/contact')}
                    style={{cursor: 'pointer'}}
                  >
                    <p className="latoBlack text-center text-md-left heading px-0 px-md-4 mw-100">
                      Looking for such a team? We should talk.
                    </p>
                    <div>
                      <Link
                        className="btn btn btn-outline-success btn-started px-4 mt-2"
                        onClick={() =>
                          Event('Get Started', 'Get Started is clicked', window.location.pathname)
                        }
                        to="/contact"
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SolutionsSection />
      <CaseStudySection />
      <Footer showContactSection={false} showPortfolioSection={false} />
    </>
  );
}

export default CPTOTemplate;
